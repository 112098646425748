import React from 'react';
import "../css/header.css"

function Header(props) {

    return (
        
        <div className='header'>
         <img src={props.imgURL} alt=''></img>
         <div className='title'>
            <h1>{props.title}</h1>
            <p>{props.subtext}</p>
            <div className='content'>{props.content}</div>
        </div>
        </div>
       
    );
  }

  export default Header;


  