import React, { useState, useEffect, useRef } from 'react';

const Carousel = ({ images }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const intervalRef = useRef(null);

  useEffect(() => {
    startInterval();

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  const goToSlide = (index) => {
    setCurrentSlide(index);
    restartInterval();
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    restartInterval();
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + images.length) % images.length);
    restartInterval();
  };

  const startInterval = () => {
    intervalRef.current = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 4000);
  };

  const restartInterval = () => {
    clearInterval(intervalRef.current);
    startInterval();
  };

  return (
    <div className="carousel-container">
      {images.map((image, index) => (
        <div
          key={index}
          className={`carousel-slide ${index === currentSlide ? 'active' : ''}`}
        >
          <img src={image} alt={`Image ${index + 1}`} />
        </div>
      ))}

      <div className="carousel-indicators">
        {images.map((_, index) => (
          <button
            key={index}
            className={`${index === currentSlide ? 'active' : ''}`}
            onClick={() => goToSlide(index)}
          />
        ))}
      </div>

      <div className="carousel-controls">
        <button className="carousel-control prev" onClick={prevSlide}>
          &lt;
        </button>
        <button className="carousel-control next" onClick={nextSlide}>
          &gt;
        </button>
      </div>
    </div>
  );
};

export default Carousel;
