import React from 'react';
import { Link } from 'react-router-dom';
import CustomNavbar from '../components/Navbar';
import Footer from '../components/Footer';
import newsData from '../data/newsData';
import { kebabCase } from 'lodash';
import projects from '../data/projectData';
import ProjectCard from '../components/projectCard';
import services from '../data/serviceData';
import Carousel from '../components/carousel';
import CertifSection from '../components/CertifSection';
import ClientSection from '../components/ClientSection';
import NewsCard from '../components/newsCard';
import "../css/homepage.css"
import "../css/main.css"


function HomePage() {
  // Get the last three news articles based on their IDs
  const lastThreeNews = newsData
  .slice(-3)
  .sort((b, a) => a.id - b.id)


  // Get the three featured projects in ascending order
  const featuredProjects = projects
    .filter((project) => project.featured)
    .sort((a, b) => a.featured - b.featured)
    .slice(0, 3);
  
  // Get the ten featured services in ascending order
    const featuredServices = services
    .filter((services) => services.featured)
    .sort((a, b) => a.featured - b.featured)
    .slice(0, 10);

    const images=["/images/homepage/elite-signature-hero-03.webp","/images/homepage/elite-signature-hero-02.webp","/images/homepage/elite-signature-hero-01.webp"]
  return (
    <div>
      <CustomNavbar />
      
      <div className="hero">
        <div className='overlay'></div>
        <Carousel images={images} />
        {/* <img src="/images/homepage/elite-signature-hero-01.webp" alt='by <a href="https://unsplash.com/@keytion?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Keytion</a> on <a href="https://unsplash.com/photos/85Km1bnKUOE?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>'/> */}
      <h1 className='animate'>PIONEERING INNOVATION IN BUILDING SOLUTIONS</h1> 
      </div>
      
      
      <div className='services-section services-section-home'>
          <h2>Providing Services Across the Middle East</h2>
          <p>At Elite Signature, we are dedicated to delivering top-notch contracting services across various industries, catering to clients with projects spanning the Middle East.</p>
          <div className='service-cards'>
          {featuredServices.map((services) => (
            <div className="service-card animate" key={services.id}>
                <p>{services.title}</p>
                <div className="overlay"></div>
                <img src={services.imgURL} alt=""></img>
            </div>
          ))}
          </div>
          <Link to="/services" className='button-link'>
          <button className='button'>All Services</button>
          </Link>
        </div>

      <div className='about-section'>
            <img src="/images/homepage/elite-signature-vision-background.webp" alt=""></img>
            <div className='about-vision-rb'>
            <div className='vision-content'>
            <h1>integrity meets vision</h1>
            </div>
            <div className='vision-content'>
            <h4>our vision</h4>
            <p>To be top - tier contractor of choice for one- stop "Industrial projects life cycle" advisory and execution process for everything from construction till handing over, we want to always be the top choice on a high level of our well-known expertise, quality, performance, and dependability in all forms of industrial projects and steel structure solution.</p>
            <Link to="/about" className='button-link'>
              <button className='button'>Our Story</button>
            </Link>
            </div>
            </div>
        </div>

      <div className='projects-section'>
        <h2>Featured Projects</h2>
        <p>Discover our projects where we proudly display an extensive array of services. From steel erection and supply and application to anchor bolt installation, fire paint systems, and shear stud implementation, our track record speaks volumes.</p>
          <div className='project-cards'>
            {featuredProjects.map((project) => (
              <ProjectCard 
                id={project.id}
                title={project.title}
                imgURL={project.images.mainImage}
              />
            ))}
          </div>  
          <Link to="/projects" className='button-link'>
          <button className='button'>More Projects</button>
          </Link>
      </div>


<ClientSection />



<div className='news-section'>
      <h2>Latest News</h2>
      <p></p>
     

      <div className='news-cards'>
      {lastThreeNews.map((news) => (
        <NewsCard 
                id={news.id}
                title={news.title}
                imgURL={news.imgURL}
              />
      ))}
      </div>
      </div>



    <CertifSection />
      <Footer />
    </div>
  );
}

export default HomePage;
