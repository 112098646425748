import React from 'react';
import { Link } from 'react-router-dom';
import { kebabCase } from 'lodash';
import "../css/main.css"


function ProjectCard(props) {

  const projectCardStyle = {
    position: 'relative',
    width: '350px',
    height: '350px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    flexGrow: '1'
  };

  const overlayStyle = {
    top: '0',
    left: '0',
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to top, black, transparent)',
    zIndex: '-1'
  };



  
  const pStyle = {
    width: '90%',
    minHeight: '4rem',
    textAlign: 'left',
    fontFamily: "'Arial Narrow Bold', sans-serif",
    fontSize: '1.3rem',
    color: 'white',
    margin: '0 0 15px',
    zIndex: '0',
  }

  const pStyle2 = {
    width: '100%',
    textDecoration: 'none',
    zIndex: '0',
    display: 'flex',
    justifyContent: 'center',
  }

  

  return (
    <div className='project-card-effect' style={projectCardStyle} key={props.id}>
      <Link style={pStyle2} to={`/projects/${encodeURIComponent(kebabCase(props.title))}`}>
        <p style={pStyle}>{props.title}</p>
        <img className='hover-image' src={props.imgURL} alt="" />
        <div style={overlayStyle}></div>

      </Link>
    </div>
  );
}

export default ProjectCard;
