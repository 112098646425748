import React from 'react';
import CustomNavbar from '../components/Navbar';
import Footer from '../components/Footer';
import Header from '../components/Header';
import "../css/main.css"
import "../css/about.css"


function About() {


  return (
    <div>
    
    <CustomNavbar />
    <Header 
      title = "About the company"
      subtext = ""
      imgURL = "/images/banners/banner-about-01.webp"
    />

    <div className='text-left'>
      <div className='text'>
        <h1>INTEGRITY MEETS VISION</h1>
        <p>Our vision is to become a top-tier contractor of choice for one-stop "Industrial projects life cycle" advisory and execution process for everything from construction till handing over, we want to always be the top choice on a high level of our well-known expertise, quality, performance, and dependability in all forms of industrial projects and steel structure solution.</p>
        </div>
        <div className='image'>
        <img src='/images/about/about-page-01.webp' alt=''></img>
        </div>
    </div>

    <div className='text-right'>
      <div className='text'>
        <h1>A MISSION TO ACHIEVE</h1>
        <p>Our mission is to provide a complete and innovative value-added of industrial projects with superior quality and on schedule, earning the customers' satisfaction. To offer each project the best possible craftsmanship, the greatest level of service available in the construction and steel structure sectors.</p>
        </div>
        <div className='image'>
        <img src='/images/about/about-page-02.webp' alt=''></img>
        </div>
    </div>

    <div className='text-left'>
      <div className='text'>
        <h1>our strategy</h1>
        <p>Our strategy is to stand out from the competition by providing the greatest and most expert services in the market we serve. As a result, our knowledge of large organizations and our close relationships with influential industrial market players give us a strategic advantage.
As a result, by placing a strong emphasis on excellent quality, cutting-edge technology, and first-rate of customer satisfaction, our marketing approach supports our whole plan in our marketing initiatives and the services we provide to our esteemed client.</p>
        </div>
        <div className='image'>
        <img src='/images/about/about-page-03.webp' alt=''></img>
        </div>
    </div>

    <div className='text-right'>
      <div className='text'>
        <h1>CERTIFIED FOR EXCELLENCE</h1>
        <p>
At Elite Signature, we boast the highest level of certifications, demonstrating our unwavering commitment to quality. With prestigious accreditations such as ISO 9001, ISO 14001, and ISO 45001, we consistently uphold stringent standards in every aspect of our work. Additionally, as certified builders recognized by AIC Steel, we have proven our expertise and reliability in the industry.</p>
        </div>
        <div className='image'>
        <img src='/images/about/about-page-04.webp' alt=''></img>
        </div>
    </div>
    <Footer />
    </div>

    
  );
}

export default About;

