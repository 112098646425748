import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "../css/navbar.css"

function CustomNavbar() {
  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className={`navbar ${scrolled ? 'navbar-scrolled' : ''}
                            ${isMenuOpen ? 'navbar-scrolled' : ''}`}>
      <div className="navbar-container">
        <Link
        to="/">
          <img
            className={`
            ${scrolled ? 'brand-disabled brand' : 'brand'}
            ${isMenuOpen ? 'brand-disabled brand' : 'brand'}
            `}
            src="/images/logo/elite-signature-logo-white.png"
            alt='Elite Signature Company Logo'
          />
          <img
            className={`
            ${scrolled ? 'brand' : 'brand-disabled brand'}
            ${isMenuOpen ? 'brand-enabled' : ''}
            `}
            src="/images/logo/elite-signature-logo-gold-navy.webp"
            alt='Elite Signature Company Logo'
          />
        </Link>

        <div className={`nav-menu ${isMenuOpen ? 'active' : ''}`}>
          <Link
            className={`navLink ${scrolled ? 'navLink-scrolled' : 'navLink-basic'}`}
            to="/about"
            onClick={closeMenu}
          >
            About
          </Link>
          <Link
            className={`navLink ${scrolled ? 'navLink-scrolled' : 'navLink-basic'}`}
            to="/services"
            onClick={closeMenu}
          >
            Services
          </Link>
          <Link
            className={`navLink ${scrolled ? 'navLink-scrolled' : 'navLink-basic'}`}
            to="/projects"
            onClick={closeMenu}
          >
            Projects
          </Link>
          <Link
            className={`navLink ${scrolled ? 'navLink-scrolled' : 'navLink-basic'}`}
            to="/contact"
            onClick={closeMenu}
          >
            Contact
          </Link>
        </div>

        <div className={`hamburger ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
          <span className={`bar 
          ${scrolled ? 'bar-scrolled' : 'bar-basic'}
          ${isMenuOpen ? 'bar-scrolled' : 'bar-basic'}
          `}></span>
           <span className={`bar 
          ${scrolled ? 'bar-scrolled' : 'bar-basic'}
          ${isMenuOpen ? 'bar-scrolled' : 'bar-basic'}
          `}></span>
           <span className={`bar 
          ${scrolled ? 'bar-scrolled' : 'bar-basic'}
          ${isMenuOpen ? 'bar-scrolled' : 'bar-basic'}
          `}></span>
        </div>
      </div>
    </nav>
  );
}

export default CustomNavbar;



// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import "../css/navbar.css"

// function CustomNavbar() {
//   const [scrolled, setScrolled] = useState(false);
//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   useEffect(() => {
//     const handleScroll = () => {
//       const scrollPosition = window.scrollY;
//       if (scrollPosition > 0) {
//         setScrolled(true);
//       } else {
//         setScrolled(false);
//       }
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, []);

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   const closeMenu = () => {
//     setIsMenuOpen(false);
//   };

//   return (
//     <nav className={`navbar ${scrolled ? 'navbar-scrolled' : ''}
//                             ${isMenuOpen ? 'navbar-scrolled' : ''}`}>
//       <div className="navbar-container">
//         <Link
//         to="/">
//           <img
//             className={`
//             ${scrolled ? 'brand-disabled brand' : 'brand'}
//             ${isMenuOpen ? 'brand-disabled brand' : 'brand'}
//             `}
//             src="/images/logo/elite-signature-logo-white.png"
//             alt='Elite Signature Company Logo'
//           />
//           <img
//             className={`
//             ${scrolled ? 'brand' : 'brand-disabled brand'}
//             ${isMenuOpen ? 'brand-enabled' : ''}
//             `}
//             src="/images/logo/elite-signature-logo-gold-navy.webp"
//             alt='Elite Signature Company Logo'
//           />
//         </Link>

//         <div className={`nav-menu ${isMenuOpen ? 'active' : ''}`}>
//           <Link
//             className={`navLink ${scrolled ? 'navLink-scrolled' : 'navLink-basic'}`}
//             to="/about"
//             onClick={closeMenu}
//           >
//             About
//           </Link>
//           <Link
//             className={`navLink ${scrolled ? 'navLink-scrolled' : 'navLink-basic'}`}
//             to="/services"
//             onClick={closeMenu}
//           >
//             Services
//           </Link>
//           <Link
//             className={`navLink ${scrolled ? 'navLink-scrolled' : 'navLink-basic'}`}
//             to="/projects"
//             onClick={closeMenu}
//           >
//             Projects
//           </Link>
//           <Link
//             className={`navLink ${scrolled ? 'navLink-scrolled' : 'navLink-basic'}`}
//             to="/contact"
//             onClick={closeMenu}
//           >
//             Contact
//           </Link>
//         </div>

//         <div className={`hamburger ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
//           <span className={`bar 
//           ${scrolled ? 'bar-scrolled' : 'bar-basic'}
//           ${isMenuOpen ? 'bar-scrolled' : 'bar-basic'}
//           `}></span>
//            <span className={`bar 
//           ${scrolled ? 'bar-scrolled' : 'bar-basic'}
//           ${isMenuOpen ? 'bar-scrolled' : 'bar-basic'}
//           `}></span>
//            <span className={`bar 
//           ${scrolled ? 'bar-scrolled' : 'bar-basic'}
//           ${isMenuOpen ? 'bar-scrolled' : 'bar-basic'}
//           `}></span>
//         </div>
//       </div>
//     </nav>
//   );
// }

// export default CustomNavbar;




// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import "../css/navbar.css"

// function CustomNavbar() {
//   const [scrolled, setScrolled] = useState(false);
//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   useEffect(() => {
//     const handleScroll = () => {
//       const scrollPosition = window.scrollY;
//       if (scrollPosition > 0) {
//         setScrolled(true);
//       } else {
//         setScrolled(false);
//       }
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, []);

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   const closeMenu = () => {
//     setIsMenuOpen(false);
//   };

//   return (
//     <nav className={`navbar ${scrolled ? 'navbar-scrolled' : ''}
//                             ${isMenuOpen ? 'navbar-scrolled' : ''}`}>
//       <div className="navbar-container">
//         <a
//         onClick={() => {window.location.href="/"}}>
//           <img
//             className={`
//             ${scrolled ? 'brand-disabled brand' : 'brand'}
//             ${isMenuOpen ? 'brand-disabled brand' : 'brand'}
//             `}
//             src="/images/logo/elite-signature-logo-white.png"
//             alt='Elite Signature Company Logo'
//           />
//           <img
//             className={`
//             ${scrolled ? 'brand' : 'brand-disabled brand'}
//             ${isMenuOpen ? 'brand-enabled' : ''}
//             `}
//             src="/images/logo/elite-signature-logo-gold-navy.webp"
//             alt='Elite Signature Company Logo'
//           />
//         </a>

//         <div className={`nav-menu ${isMenuOpen ? 'active' : ''}`}>
//           <a
//             className={`navLink ${scrolled ? 'navLink-scrolled' : 'navLink-basic'}`}
//             onClick={() => {window.location.href="/#/about"}}
            
//           >
//             About
//           </a>
//           <a
//             className={`navLink ${scrolled ? 'navLink-scrolled' : 'navLink-basic'}`}
//             href='/#/services'
//             onClick={() => { window.location.reload(); }}
//           >
//             Services
//           </a>
//           <a
//             className={`navLink ${scrolled ? 'navLink-scrolled' : 'navLink-basic'}`}
//             onClick={() => {window.location.href="/projects"}}
//           >
//             Projects
//           </a>
//           <a
//             className={`navLink ${scrolled ? 'navLink-scrolled' : 'navLink-basic'}`}
//             onClick={() => {window.location.href="/contact"}}
//           >
//             Contact
//           </a>
//         </div>

//         <div className={`hamburger ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
//           <span className={`bar 
//           ${scrolled ? 'bar-scrolled' : 'bar-basic'}
//           ${isMenuOpen ? 'bar-scrolled' : 'bar-basic'}
//           `}></span>
//            <span className={`bar 
//           ${scrolled ? 'bar-scrolled' : 'bar-basic'}
//           ${isMenuOpen ? 'bar-scrolled' : 'bar-basic'}
//           `}></span>
//            <span className={`bar 
//           ${scrolled ? 'bar-scrolled' : 'bar-basic'}
//           ${isMenuOpen ? 'bar-scrolled' : 'bar-basic'}
//           `}></span>
//         </div>
//       </div>
//     </nav>
//   );
// }

// export default CustomNavbar;