const services = [
    {
        id: 1,
        featured: 1,
        title: "turn key industrial projects",
        imgURL: "/images/services/turnkey-industrial-projects.webp",
    },
    {
        id: 2,
        featured: 1,
        title: "steel fabrication",
        imgURL: "/images/services/steel-fabrication.webp",
    },
    {
        id: 3,
        featured: 3,
        title: "steel & panels erection",
        imgURL: "/images/services/steel-panel.webp",
    },
    {
        id: 4,
        featured: 3,
        title: "steel works modifications",
        imgURL: "/images/services/steel-work-modifications.webp",
    },
    {
        id: 5,
        featured: 3,
        title: "fire paint system",
        imgURL: "/images/services/fire-paint.webp",
    },
    {
        id: 6,
        featured: 3,
        title: "welding works",
        imgURL: "/images/services/welding-works.webp",
    },
    {
        id: 7,
        featured: 1,
        title: "engineering services",
        imgURL: "/images/services/engineering-services.webp",
    },
    {
        id: 8,
        featured: 1,
        title: "industrial flooring",
        imgURL: "/images/services/industrial-flooring.webp",
    },
    {
        id: 9,
        featured: 1,
        title: "steel miscellanies manufacturing in house",
        imgURL: "/images/services/steel-1.webp",
    },
    {
        id: 10,
        featured: 1,
        title: "installation of shear studs works",
        imgURL: "/images/services/shear-stud.webp",
    },
    {
        id: 11,
        featured: 1,
        title: "racking system installation",
        imgURL: "/images/services/racking-system.webp",
    },
    {
        id: 12,
        featured: 1,
        title: "anchor bolts installation",
        imgURL: "/images/services/anchor-bolts.webp",
    }
];

export default services;