import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { kebabCase } from 'lodash';
import news from '../data/newsData';
import CustomNavbar from '../components/Navbar';
import Footer from '../components/Footer';
import Header from '../components/Header';
import NewsCard from '../components/newsCard';
import "../css/main.css"




function NewsPage() {
  const { newsTitle } = useParams();
  const currentNews = news.find((article) => kebabCase(article.title) === newsTitle);

  if (!currentNews) {
    return <div>News article not found</div>;
  }

  const currentNewsIndex = news.indexOf(currentNews);
  const totalNews = news.length;

  let nextThreeNews = [];
  if (totalNews >= 3) {
    for (let i = 1; i <= 3; i++) {
      const nextIndex = (currentNewsIndex + i) % totalNews;
      nextThreeNews.push(news[nextIndex]);
    }
  } else {
    nextThreeNews = news;
  }

  return (
    <div>
<CustomNavbar />
      <Header 
      title = "Latest News"
      subtext = ""
      imgURL = "/images/banners/banner-project-01.webp"
    />

    <div className='news-page'>
    <div className='news-content'>
      <h2>{currentNews.title}</h2>
      <img src={currentNews.imgURL} alt=''></img>
      <p>{currentNews.content}</p>
      </div>
      </div>


 {/* Render other news article details */}
<div className='projects-section'>
      <h2>More News</h2>
      <p></p>
      <div className='project-cards'>
      {nextThreeNews.map((news) => (
        <NewsCard 
                id={news.id}
                title={news.title}
                imgURL={news.imgURL}
              />
      ))}
      </div>
</div>
      

      
      <Footer />
    </div>
  );
}

export default NewsPage;
