import React, { useState } from 'react';
import ImagePopup from './ImagePopup';

function CertifSection() {
  // Define the list of image URLs for the certificates in the div
  const certificateImagesDiv = [
    "/images/certificates/iso-logo-01.webp",
    "/images/certificates/iso-logo-02.webp",
    "/images/certificates/iso-logo-03.webp",
    "/images/certificates/certified-builder-01.webp",
  ];

  // Define the list of image URLs for the certificates in the popup
  const certificateImagesPopup = [
    "/images/certificates/certif-01.jpg",
    "/images/certificates/certif-02.jpg",
    "/images/certificates/certif-03.jpg",
    "/images/certificates/certif-04.jpg",
  ];

  // State to keep track of which certificate's popup is open
  const [selectedCertificate, setSelectedCertificate] = useState(null);

  // Function to open the popup for a specific certificate
  const handleOpenPopup = (index) => {
    setSelectedCertificate(index);
  };

  // Function to close the popup
  const handleClosePopup = () => {
    setSelectedCertificate(null);
  };

  return (
    <div className='certif'>
      <h2>Certifications & Accreditations</h2>
      <p>We boast the highest level of certifications. With prestigious accreditations such as ISO 9001, ISO 14001, and ISO 45001. Additionally, we are recognized certified builders by AIC.</p>
      <div className='certif-cards'>
        {certificateImagesDiv.map((imageUrl, index) => (
          <div
            key={index}
            className='certif-card'
            onClick={() => handleOpenPopup(index)}
          >
            <img src={imageUrl} alt={`Certificate ${index + 1}`} />
          </div>
        ))}
      </div>
      {selectedCertificate !== null && (
        <ImagePopup
          imageUrl={certificateImagesPopup[selectedCertificate]}
          onClose={handleClosePopup}
          style={{ // Add the styles for the popup image here
            maxWidth: '100%',
            maxHeight: '100%',
            // borderRadius: '5px',
          }}
        />
      )}
    </div>
  );
}

export default CertifSection;
