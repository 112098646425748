import React from 'react';


function ServiceCard(props) {

  const serviceCardStyle = {
    position: 'relative',
    width: '350px',
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: '1'
  };

  const overlayStyle = {
    top: '0',
    left: '0',
    zIndex: '97',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    opacity: '0.4'
  };

  const imageStyle = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  };

  const pStyle = {
    width: '55%',
    textAlign: 'center',
    zIndex: '98',
    fontFamily: "Anton, sans-serif",
    fontSize: '1.5rem',
    color: 'white',
    margin: '5px',
    textTransform: 'uppercase',
  }


  return (
    <div style={serviceCardStyle} key={props.id}>
        <p style={pStyle}>{props.title}</p>
        <div style={overlayStyle}></div>
        <img style={imageStyle} src={props.imgURL} alt="" />
    </div>
  );
}

export default ServiceCard;
