import React from 'react';
import Modal from 'react-modal';

const ImagePopup = ({ imageUrl, onClose }) => {
  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          zIndex: 1000,
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '10px',
          border: 'none',
          padding: 0,
          width: 'auto', // Adjust the width to fit the image
          maxHeight: '80vh', // Limit the maximum height of the popup
          overflow: 'auto', // Enable vertical scrolling if needed
          backgroundColor: 'transparent', // Make the background transparent
        },
      }}
    >
      <img
        src={imageUrl}
        alt="Popup"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain', // Adjust the image to fit the container without cropping
          borderRadius: '10px',
        }}
      />
      <button
        onClick={onClose}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          backgroundColor: '#ddd',
          borderRadius: '50%',
          border: 'none',
          fontSize: '25px',
          color: '#000',
          cursor: 'pointer',
          width: '35px',
          height: '35px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        &times;
      </button>
    </Modal>
  );
};

export default ImagePopup;
