import React, { useState } from 'react';

function ClientSection() {
  

  return (

    <div className='clients-section'>
    <h2>Our Clients</h2>
    <div class="slider">
  <div class="slide-track">
      <div class="slide">
          <img src="/images/logo/logo-01.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-02.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-03.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-04.png" height="100" width='250' alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-05.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-06.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-07.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-08.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-09.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-10.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-11.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-12.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-13.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-14.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-15.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-16.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-17.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-18.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-19.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-20.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-22.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-23.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-24.png" height="100" width='250'  alt="" />
      </div>
      <div class="slide">
          <img src="/images/logo/logo-25.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-01.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-02.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-03.png" height="100" width='250'  alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-04.png" height="100" width='250' alt="" />
      </div>
  <div class="slide">
          <img src="/images/logo/logo-05.png" height="100" width='250'  alt="" />
      </div>

  
  </div>
</div>
</div>
    
  );
}

export default ClientSection;
