const news = [
    {
        id: 1,
        title: "Elite Signature Contracting Awarded Neom & Sharma Construction Villages Project",
        content: "NEOM, the visionary city in Saudi Arabia, is collaborating with Al-Fanar and AIC to create the Neom & Sharma Construction Villages project. Spanning 139,000m2, this initiative utilizes pre-engineered buildings, emphasizing sustainability in line with NEOM's commitment to innovation and eco-conscious practices. Elite Signature Contracting has been awarded the prestigious role of contributing to this groundbreaking project, further reinforcing its significance in shaping NEOM's future.",
        imgURL: "/images/news/01-neom-construction-village.webp",
    },
    {
        id: 2,
        title: "Elite Signature Contracting Awarded Al-Jubail Desalination Plant Phase 2 Project",
        content: "SWCC (Saline Water Conversion Corporation) takes another stride towards technological advancement by collaborating with S.S.E.M and AIC for the Replacement of Technologies and Ended Lifetime Assets at Al-Jubail Desalination Plant Phase 2. With a total tonnage of 2500 tons, this ambitious project involves the installation of Pre-Engineered Buildings (PEBs) for workshop and warehouse buildings in Jubail. Elite Signature Contracting's recognition as the main contractor further solidifies the significance of this endeavor in fostering a sustainable future for water conversion infrastructure.",
        imgURL: "/images/news/02-al-jubail-desalination-plant.webp",
    },
    {
        id: 3,
        title: "Elite Signature Contracting Awarded a Major Project with Aqua Bridge Company",
        content: "Aqua Bridge Company and Elite Signature Contracting are joining forces once again for a remarkable project. With a total area of 3000 square meters, this collaboration involves the supply and installation of a Pre-Engineered Building (PEB) warehouse facility in the futuristic city of Neom. The steel for this project, proudly manufactured by AIC, reinforces its commitment to sustainable construction practices. The significance of this venture lies in its contribution to Neom's ever-evolving infrastructure, bringing it one step closer to becoming a beacon of technological progress and environmental sustainability.",
        imgURL: "/images/news/03-aqua-bridge.webp",
    },
    
];

export default news;